import { useStaticQuery, graphql } from 'gatsby';
import React from 'react'
import Hero from '../components/Hero/Hero';
import Template from '../components/layout';
import RichContent from '../components/RickContent/RichContent';
import { Helmet } from "react-helmet";

function RodoPage() {
  const { nodes } = useStaticQuery(
    graphql`
    query RodoPage {
      allContentfulPage(filter: {slug: {eq: "rodo"}}) {
        nodes {
          title
          slug
          text {
            raw
          }
        }
      }
      }       
    `
  ).allContentfulPage;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Koktajlove 🍹 RODO</title>
        <link rel="canonical" href="https://koktajlove.pl/rodo" />
      </Helmet>
      <Template>
        <Hero title="RODO" subtitle={false} />
        <RichContent raw={nodes[0].text} />
      </Template>
    </>
  )
}

export default RodoPage;
